<template>
    <GDialog
        v-model="modalStore.addParcelModal.show"
        max-width="500"
        border-radius="10"
        @update:model-value="hideModal"
    >
        <div v-if="modalStore.addParcelModal.show" class="add-parcel-modal">
            <div class="container">
                <div class="modal-body">
                    <div class="modal-info">
                        <div class="section">
                            <div class="section-title text-h2 text-bold">Деталі відправника</div>
                            <div class="select-wrap">
                                <div class="select-label text-h5 text-grey">Мобільний телефон</div>
                                <ClientSearchComponent
                                    v-model="parcelData.sender.phones[0].phone"
                                    :phone-invalid="senderPhoneInvalid"
                                    @client-select="
                                        (option) => handleClientSelect(option, 'sender')
                                    "
                                    @client-clear="() => handleClientClear('sender')"
                                />
                            </div>
                            <InputComponent
                                v-model="parcelData.sender.name"
                                type="text"
                                label="Імʼя"
                                placeholder="Імʼя"
                            />
                            <InputComponent
                                v-model="parcelData.sender.surname"
                                type="text"
                                label="Прізвище"
                                placeholder="Прізвище"
                            />
                        </div>

                        <div class="section">
                            <div class="section-title text-h2 text-bold">Деталі отримувача</div>

                            <div class="select-wrap">
                                <div class="select-label text-h5 text-grey">Мобільний телефон</div>
                                <ClientSearchComponent
                                    v-model="parcelData.recipient.phones[0].phone"
                                    :with-addresses="true"
                                    :phone-invalid="recipientPhoneInvalid"
                                    @client-select="
                                        (option) => handleClientSelect(option, 'recipient')
                                    "
                                    @client-clear="() => handleClientClear('recipient')"
                                />
                            </div>

                            <InputComponent
                                v-model="parcelData.recipient.name"
                                type="text"
                                label="Імʼя"
                                placeholder="Імʼя"
                            />
                            <InputComponent
                                v-model="parcelData.recipient.surname"
                                type="text"
                                label="Прізвище"
                                placeholder="Прізвище"
                            />
                        </div>

                        <div class="section">
                            <div class="section-title text-h2 text-bold">Деталі посилки</div>
                            <div class="select-wrap">
                                <div class="select-label text-h5 text-grey">Напрям</div>
                                <Multiselect
                                    ref="directionSelect"
                                    v-model="parcelData.parcel.trip_direction_id"
                                    placeholder="Оберіть напрям"
                                    value-prop="id"
                                    :options="directionOptions"
                                    :resolve-on-load="true"
                                    :close-on-select="true"
                                    @change="handleDirectionChange"
                                >
                                    <template #caret>
                                        <CollapseToggler :opened="false" />
                                    </template>

                                    <template #singlelabel="{ value }">
                                        <div class="value-text">
                                            <span class="text-h3">{{ value.name }}</span>
                                        </div>
                                    </template>
                                    <template #option="{ option }">
                                        <div class="option-text">
                                            <span class="text-h4">{{ option.name }}</span>
                                        </div>
                                    </template>

                                    <template #nooptions>
                                        <div class="no-options-drop text-h5">
                                            Не знайдено жодного напряму
                                        </div>
                                    </template>
                                </Multiselect>
                            </div>
                            <InputComponent
                                v-model="parcelData.parcel.internal_id"
                                type="text"
                                label="Внутрішній ідентифікатор"
                                placeholder="Внутрішній ідентифікатор"
                            />
                            <InputComponent
                                v-model="parcelData.parcel.weight"
                                type="number"
                                label="Приблизна вага"
                                placeholder="Приблизна вага"
                            />
                            <InputComponent
                                v-model="parcelData.parcel.width"
                                type="number"
                                label="Ширина"
                                placeholder="Ширина"
                            />
                            <InputComponent
                                v-model="parcelData.parcel.height"
                                type="number"
                                label="Висота"
                                placeholder="Висота"
                            />
                            <InputComponent
                                v-model="parcelData.parcel.length"
                                type="number"
                                label="Довжина"
                                placeholder="Довжина"
                            />
                            <InputComponent
                                v-model="parcelData.parcel.description"
                                type="text"
                                label="Опис"
                                placeholder="Опис"
                            />
                        </div>

                        <div class="section">
                            <div class="section-title text-h2 text-bold">Варіанти доставки</div>

                            <div class="delivery-options">
                                <RadioComponent
                                    v-model="parcelData.recipient.address.address_type"
                                    :options="deliveryOptions"
                                />
                            </div>

                            <div v-if="filteredAddresses.length" class="select-wrap">
                                <div class="select-label text-h5 text-grey">
                                    Виберіть адресу отримання
                                </div>
                                <Multiselect
                                    ref="recipientAddressSelect"
                                    class="address-select"
                                    placeholder="Виберіть адресу отримання"
                                    value-prop="address"
                                    :options="filteredAddresses"
                                    :resolve-on-load="true"
                                    :close-on-select="true"
                                    @select="(item, option) => handleRecipientAddressChange(option)"
                                    @clear="handleRecipientAddressClear"
                                >
                                    <template #caret>
                                        <CollapseToggler :opened="false" />
                                    </template>

                                    <template #singlelabel="{ value }">
                                        <div class="value-text">
                                            <div class="text-h3 text-bold">
                                                {{ value.city.name }}
                                            </div>
                                            <div class="text-h3">
                                                {{ value.address }}
                                            </div>
                                        </div>
                                    </template>
                                    <template #option="{ option }">
                                        <div class="option-text">
                                            <div class="text-h3 text-bold">
                                                {{ option.city.name }}
                                            </div>
                                            <div class="text-h4">
                                                {{ option.address }}
                                            </div>
                                        </div>
                                    </template>

                                    <template #nooptions>
                                        <div class="no-options-drop text-h5">
                                            Немає збережених адрес такого типу
                                        </div>
                                    </template>
                                </Multiselect>
                            </div>

                            <div class="select-wrap">
                                <div class="select-label text-h5 text-grey">Населений пункт</div>
                                <Multiselect
                                    ref="recipientCitySelect"
                                    placeholder="Населений пункт"
                                    value-prop="id"
                                    class="city-select"
                                    :delay="500"
                                    :searchable="true"
                                    :filter-results="false"
                                    :resolve-on-load="true"
                                    :options="cityList"
                                    :close-on-select="true"
                                    @select="(item, option) => handleCitySelect(option)"
                                    @clear="handleCityClear"
                                >
                                    <template #caret>
                                        <CollapseToggler :opened="false" />
                                    </template>

                                    <template #singlelabel="{ value }">
                                        <div class="value-text">
                                            <span class="text-h3">{{ value.city }}</span>
                                        </div>
                                    </template>
                                    <template #option="{ option }">
                                        <div class="option-text city-option-text">
                                            <span class="city-name text-h4 text-bold">
                                                {{ option.city }}
                                            </span>
                                            <span class="city-region text-h5 text-grey">
                                                {{ option.region }}
                                            </span>
                                            <span class="country text-h5 text-grey">
                                                {{ option.country }}
                                            </span>
                                        </div>
                                    </template>

                                    <template #nooptions>
                                        <div class="no-options-drop text-h5">
                                            Введіть для пошуку
                                        </div>
                                    </template>
                                </Multiselect>
                            </div>

                            <template
                                v-if="
                                    parcelData.recipient.address.address_type === 'new-post' &&
                                    parcelData.recipient.address.has_nova_post
                                "
                            >
                                <div class="select-wrap">
                                    <div class="select-label text-h5 text-grey">
                                        Відділення Нової Пошти
                                    </div>
                                    <Multiselect
                                        ref="recipientNpSelect"
                                        :disabled="!parcelData.recipient.address.id"
                                        placeholder="Відділення Нової Пошти"
                                        value-prop="id"
                                        class="np-select"
                                        :delay="500"
                                        :searchable="true"
                                        :filter-results="false"
                                        :resolve-on-load="true"
                                        :options="npList"
                                        :close-on-select="true"
                                        @select="(item, option) => handleNpSelect(option)"
                                        @clear="handleNpClear"
                                    >
                                        <template #caret>
                                            <CollapseToggler :opened="false" />
                                        </template>

                                        <template #singlelabel="{ value }">
                                            <div class="value-text">
                                                <span class="text-h3">{{ value.city }}</span>
                                            </div>
                                        </template>
                                        <template #option="{ option }">
                                            <div class="option-text city-option-text">
                                                <span class="city-name text-h4">
                                                    {{ option.city }}
                                                </span>
                                            </div>
                                        </template>

                                        <template #nooptions>
                                            <div class="no-options-drop text-h5">
                                                Введіть для пошуку
                                            </div>
                                        </template>
                                    </Multiselect>
                                </div>
                            </template>

                            <template v-else>
                                <InputComponent
                                    v-model="parcelData.recipient.address.location_name"
                                    type="text"
                                    label="Адреса отримання"
                                    placeholder="Адреса отримання"
                                />

                                <InputComponent
                                    v-model="parcelData.recipient.address.zip_code"
                                    type="number"
                                    label="Поштовий індекс"
                                    placeholder="Поштовий індекс"
                                />
                            </template>
                        </div>

                        <div class="select-wrap">
                            <div class="select-label text-h5 text-grey">Оберіть кінцеву точку</div>
                            <Multiselect
                                ref="arrivalPointSelect"
                                v-model="parcelData.arrival.route_point_id"
                                placeholder="Оберіть кінцеву точку"
                                value-prop="id"
                                :options="activeRouteStore.routeData.points"
                                :resolve-on-load="true"
                                :close-on-select="true"
                                @change="handlePointChange"
                            >
                                <template #caret>
                                    <CollapseToggler :opened="false" />
                                </template>

                                <template #singlelabel="{ value }">
                                    <div class="value-text">
                                        <span class="text-h3">{{ value.address }}</span>
                                    </div>
                                </template>
                                <template #option="{ option }">
                                    <div class="option-text">
                                        <span class="text-h4">{{ option.address }}</span>
                                    </div>
                                </template>

                                <template #nooptions>
                                    <div class="no-options-drop text-h5">
                                        Не знайдено жодної точки
                                    </div>
                                </template>
                            </Multiselect>
                        </div>

                        <div class="select-wrap">
                            <div class="select-label text-h5 text-grey">Оберіть дію на точці</div>
                            <Multiselect
                                ref="arrivalActionSelect"
                                v-model="parcelData.arrival.action_type"
                                placeholder="Оберіть дію на точці"
                                value-prop="key"
                                :options="availablePointActions"
                                :resolve-on-load="true"
                                :close-on-select="true"
                                @change="handleActionChange"
                            >
                                <template #caret>
                                    <CollapseToggler :opened="false" />
                                </template>

                                <template #singlelabel="{ value }">
                                    <div class="value-text">
                                        <img :src="value.icon" />
                                        <span class="text-h3">{{ value.label }}</span>
                                    </div>
                                </template>
                                <template #option="{ option }">
                                    <div class="option-text">
                                        <img :src="option.icon" />
                                        <span class="text-h4">{{ option.label }}</span>
                                    </div>
                                </template>

                                <template #nooptions>
                                    <div class="no-options-drop text-h5">
                                        Не знайдено жодної дії
                                    </div>
                                </template>
                            </Multiselect>
                        </div>

                        <div class="status-row">
                            <div class="text-h5 text-grey">Статус оплати:</div>
                            <StatusComponent
                                :status-options="paymentStatusOptions"
                                :current-status="currentPaymentStatus"
                                @update-status="updatePaymentStatus"
                            />
                        </div>

                        <ImagesComponent
                            title-class="text-h3 text-bold"
                            form-data-key="images[]"
                            :block-data="imagesPreview"
                            block-title="Фото"
                            @load-image="loadImage"
                        />

                        <div class="comment-wrap">
                            <textarea
                                v-model="parcelData.comment"
                                placeholder="Поле для приміток"
                                class="comment-input text-h3"
                            >
                            </textarea>
                        </div>
                    </div>
                </div>

                <div class="modal-buttons shadow-sm">
                    <div class="container">
                        <ButtonComponent
                            class="cancel-btn"
                            btn-style="grey"
                            text="Скасувати"
                            @click="hideModal"
                        />

                        <ButtonComponent
                            btn-style="gradient"
                            class="text-h2"
                            text="Створити"
                            @click="submitParcelCreate"
                        />
                    </div>
                </div>

                <ImageDeleteModal modal-title="Видалити фото?" @submit-delete="submitImageDelete" />
            </div>
        </div>
    </GDialog>
</template>

<script>
import InputComponent from '@/components/form/InputComponent.vue'
import CollapseToggler from '@/components/CollapseToggler.vue'
import StatusComponent from '@/components/StatusComponent.vue'
import ImagesComponent from '@/components/ImagesComponent.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'
import ImageDeleteModal from '@/components/modals/ImageDeleteModal.vue'
import Multiselect from '@vueform/multiselect'
import actionMixin from '@/mixins/actionMixin.js'
import statusesMixin from '@/mixins/statusesMixin.js'
import { useActiveRouteStore, useModalStore } from '@/stores/index.js'
import fetchWrapper from '@/helpers/fetch-wrapper.js'
import RadioComponent from '@/components/form/RadioComponent.vue'
import npLogo from '@/assets/images/np-delivery-logo.svg'
import ClientSearchComponent from '@/components/form/ClientSearchComponent.vue'
import { parsePhone } from '@/helpers/parse-phone.js'

export default {
    name: 'AddParcelModal',
    components: {
        ClientSearchComponent,
        RadioComponent,
        ImageDeleteModal,
        ImagesComponent,
        StatusComponent,
        ButtonComponent,
        InputComponent,
        Multiselect,
        CollapseToggler
    },
    mixins: [actionMixin, statusesMixin],
    emits: ['hide-overlay'],
    setup() {
        const activeRouteStore = useActiveRouteStore()
        const modalStore = useModalStore()

        return { activeRouteStore, modalStore }
    },
    data() {
        return {
            parcelData: {
                sender: {
                    name: '',
                    surname: '',
                    phones: [
                        {
                            phone: '',
                            communication_types: {
                                viber: false,
                                telegram: false,
                                whatsapp: false
                            }
                        }
                    ]
                },
                recipient: {
                    name: '',
                    surname: '',
                    phones: [
                        {
                            phone: '',
                            communication_types: {
                                viber: false,
                                telegram: false,
                                whatsapp: false
                            }
                        }
                    ],
                    address: {
                        id: '',
                        name: '',
                        warehouse_id: '',
                        location_name: '',
                        zip_code: '',
                        address_type: 'address',
                        has_nova_post: false
                    }
                },
                parcel: {
                    trip_direction_id: null,
                    internal_id: null,
                    weight: null,
                    length: null,
                    width: null,
                    height: null,
                    description: ''
                },
                arrival: {
                    route_point_id: null,
                    action_type: ''
                },
                payment_status: 'Not paid',
                comment: '',
                images: []
            },
            deliveryOptions: [
                { value: 'address', label: 'Адреса' },
                { value: 'new-post', label: 'Нова пошта', img: npLogo }
                // { value: 'new-post-courier', label: 'Курʼєр', img: npLogo }
            ],
            addressesList: [],
            imagesPreview: [],
            senderPhoneInvalid: false,
            recipientPhoneInvalid: false
        }
    },
    computed: {
        currentPaymentStatus() {
            return this.paymentStatusOptions.filter((status) => {
                return status.value === this.parcelData.payment_status
            })[0]
        },

        availablePointActions() {
            return this.actionsOptionsArray.filter((item) => {
                if (item.key === 'finish_trip' || item.key === 'finish_transfer') {
                    return item
                }
            })
        },

        filteredAddresses() {
            const addressType = this.parcelData.recipient.address.address_type

            return this.addressesList?.filter((item) => {
                return item.postal_data.type === addressType
            })
        }
    },
    watch: {
        'parcelData.recipient.address.address_type': {
            handler() {
                this.handleRecipientAddressClear()
            }
        }
    },
    methods: {
        handleClientSelect(option, type) {
            if (type === 'sender') {
                this.parcelData.sender = { ...option, phones: [{ phone: option.phone }] }
            }

            if (type === 'recipient') {
                if (option.addresses?.length) {
                    this.addressesList = option.addresses
                    delete option.addresses
                }

                this.parcelData.recipient = {
                    ...option,
                    phones: [{ phone: option.phone }],
                    address: this.parcelData.recipient.address
                }
            }
        },

        handleClientClear(type) {
            if (type === 'sender') {
                this.parcelData.sender.phones[0].phone = ''
            }

            if (type === 'recipient') {
                this.parcelData.recipient.phones[0].phone = ''
                this.addressesList = []
            }
        },

        updatePaymentStatus(status) {
            this.parcelData.payment_status = status
        },

        async handleRecipientAddressChange(option) {
            this.parcelData.recipient.address.zip_code = option.zip_code
            this.$refs.recipientCitySelect?.select({
                id: option.city.id,
                city: option.city.name,
                region: '',
                country: ''
            })
            this.parcelData.recipient.address.has_nova_post =
                option?.postal_data?.type === 'new-post'

            await new Promise((resolve) => setTimeout(resolve, 50))

            if (this.parcelData.recipient.address.address_type === 'new-post') {
                this.$refs.recipientNpSelect?.select({
                    id: option.postal_data?.warehouse_id,
                    city: option.address
                })
            } else {
                this.parcelData.recipient.address.location_name = option.address
            }

            this.$refs.recipientAddressSelect?.blur()
        },

        handleRecipientAddressClear() {
            this.parcelData.recipient.address = {
                id: '',
                name: '',
                warehouse_id: '',
                location_name: '',
                zip_code: '',
                address_type: this.parcelData.recipient.address.address_type
            }

            if (this.$refs.recipientCitySelect?.textValue) {
                this.$refs.recipientCitySelect?.clear()
            }

            if (this.$refs.recipientNpSelect?.textValue) {
                this.$refs.recipientNpSelect?.clear()
            }

            this.$refs.recipientAddressSelect?.blur()
        },

        async cityList(query) {
            if (query?.length > 0) {
                const options = await fetchWrapper.get(
                    `${import.meta.env.VITE_API_URL}/locations?search=${query}`
                )

                if (!options.length) {
                    return
                }

                return options.map((item) => ({
                    id: item.id,
                    city: item.city,
                    region: item.region,
                    country: item.country.code,
                    has_nova_post: item.has_nova_post
                }))
            }
        },

        handleCitySelect(option) {
            this.parcelData.recipient.address.id = option.id
            this.parcelData.recipient.address.name = option.city
            this.parcelData.recipient.address.has_nova_post = option.has_nova_post
            this.parcelData.recipient.address.warehouse_id = ''

            if (this.$refs.recipientNpSelect?.textValue) {
                this.$refs.recipientNpSelect?.clear()
            }

            this.$refs.recipientCitySelect?.blur()
        },

        handleCityClear() {
            this.parcelData.recipient.address.id = ''
            this.parcelData.recipient.address.name = ''
            this.parcelData.recipient.address.has_nova_post = false
            this.parcelData.recipient.address.warehouse_id = ''

            if (this.$refs.recipientAddressSelect?.textValue) {
                this.$refs.recipientAddressSelect?.clear()
            }

            this.$refs.recipientCitySelect?.blur()
        },

        async npList(query) {
            if (query?.length > 0) {
                const options = await fetchWrapper.get(
                    `${import.meta.env.VITE_API_URL}/locations?city_id=${
                        this.parcelData.recipient.address.id
                    }&search=${query}`
                )

                if (!options.length) {
                    return
                }

                return options.map((item) => ({
                    id: item.id,
                    city: item.city
                }))
            }
        },

        handleNpSelect(option) {
            this.parcelData.recipient.address.warehouse_id = option.id
            this.parcelData.recipient.address.location_name = option.city
            this.$refs.recipientNpSelect?.blur()
        },

        handleNpClear() {
            this.parcelData.recipient.address.warehouse_id = ''
            this.parcelData.recipient.address.location_name = ''

            if (this.$refs.recipientAddressSelect?.textValue) {
                this.$refs.recipientAddressSelect?.clear()
            }

            this.$refs.recipientNpSelect?.blur()
        },

        handlePointChange() {
            this.$refs.arrivalPointSelect.blur()
        },

        handleDirectionChange() {
            this.$refs.directionSelect.blur()
        },

        handleActionChange() {
            this.$refs.arrivalActionSelect.blur()
        },

        loadImage(formData) {
            this.convertFilesToURLs(formData)
        },

        convertFilesToURLs(formData) {
            return new Promise(() => {
                const fileURLs = []
                const files = []
                const promises = []

                formData.getAll('images[]').forEach((file) => {
                    const reader = new FileReader()

                    const promise = new Promise((resolveReader, rejectReader) => {
                        reader.onload = function (event) {
                            fileURLs.push({ url: event.target.result })
                            files.push(file)
                            resolveReader()
                        }
                        reader.onerror = function (error) {
                            rejectReader(error)
                        }
                    })

                    reader.readAsDataURL(file)
                    promises.push(promise)
                })

                Promise.all(promises)
                    .then(() => {
                        this.imagesPreview.push(...fileURLs)
                        this.imagesPreview.forEach((image, index) => {
                            image.id = index
                        })

                        this.parcelData.images.push(...files)
                    })
                    .catch((error) => {
                        this.$toast.error(error)
                    })
            })
        },

        submitImageDelete(itemIndex) {
            this.imagesPreview.splice(itemIndex, 1)
            this.parcelData.images.splice(itemIndex, 1)
            this.imagesPreview.forEach((image, index) => {
                image.id = index
            })
        },

        async directionOptions() {
            const options = await fetchWrapper.post(
                `${import.meta.env.VITE_DRIVER_URL}/trip-directions/load-list`
            )

            if (!options.length) {
                return
            }

            return options
        },

        validatePhone(phone) {
            try {
                return parsePhone(phone)
            } catch {
                return false
            }
        },

        submitParcelCreate() {
            const senderPhone = this.parcelData.sender.phones[0].phone
            const recipientPhone = this.parcelData.recipient.phones[0].phone

            const isSenderPhoneValid = this.validatePhone(senderPhone)
            const isRecipientPhoneValid = this.validatePhone(recipientPhone)

            this.senderPhoneInvalid = !isSenderPhoneValid
            this.recipientPhoneInvalid = !isRecipientPhoneValid

            if (!isSenderPhoneValid) {
                this.$nextTick(() => {
                    this.$toast.error('Невірний формат номеру телефону відправника')
                })
            }
            if (!isRecipientPhoneValid) {
                this.$nextTick(() => {
                    this.$toast.error('Невірний формат номеру телефону отримувача')
                })
            }

            if (!isSenderPhoneValid || !isRecipientPhoneValid) return

            fetchWrapper
                .post('/parcels/store', this.parcelData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then((response) => {
                    this.activeRouteStore.updateActiveRoute(response.route)
                    this.hideModal()
                    this.$toast.success('Посилку добавлено')
                    this.openEntityModal(response.entity)
                })
        },

        openEntityModal(entity) {
            this.modalStore.entityModal.show = true
            this.modalStore.entityModal.entityType = entity.type
            this.modalStore.entityModal.entityId = entity.id
        },

        hideModal() {
            this.modalStore.addParcelModal.show = false
            this.$emit('hide-overlay')
            this.parcelData = {
                sender: {
                    name: '',
                    surname: '',
                    phones: [
                        {
                            phone: '',
                            communication_types: {
                                viber: false,
                                telegram: false,
                                whatsapp: false
                            }
                        }
                    ]
                },
                recipient: {
                    name: '',
                    surname: '',
                    phones: [
                        {
                            phone: '',
                            communication_types: {
                                viber: false,
                                telegram: false,
                                whatsapp: false
                            }
                        }
                    ],
                    address: {
                        id: '',
                        name: '',
                        warehouse_id: '',
                        location_name: '',
                        zip_code: '',
                        address_type: 'address',
                        has_nova_post: false
                    }
                },
                parcel: {
                    trip_direction_id: null,
                    internal_id: null,
                    weight: null,
                    length: null,
                    width: null,
                    height: null,
                    description: ''
                },
                arrival: {
                    route_point_id: null,
                    action_type: ''
                },
                payment_status: 'Not paid',
                comment: '',
                images: []
            }
            this.addressesList = []
            this.imagesPreview = []
        }
    }
}
</script>

<style scoped lang="scss">
.add-parcel-modal {
    .section-title {
        margin-bottom: 1rem;
    }

    .status-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.25rem 0;
        border-top: 1px solid $grey-light;
        border-bottom: 1px solid $grey-light;
    }

    .comment-wrap {
        padding: 1.25rem 0 0 0;
        margin-bottom: 1.25rem;

        .comment-input {
            padding: 0.7rem 1rem;
            width: 100%;
            height: 6rem;
            border: 1px solid $grey-light;
            border-radius: 0.7rem;
            appearance: none;
            overflow: hidden;
            resize: none;
        }
    }
}

.select-wrap {
    display: flex;
    flex-direction: column;
}

.address-select {
    .value-text,
    .option-text {
        flex-direction: column;
        align-items: flex-start;
    }
}

.value-text,
.option-text {
    display: flex;
    align-items: center;

    img {
        margin-right: 0.25rem;
    }
}

.client-option-text {
    flex-direction: column;
    align-items: flex-start;
}

.city-option-text {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    padding-right: 2rem;

    .city-name {
        margin-right: 0.25rem;
    }

    .city-region {
        margin-right: 0.25rem;
    }

    .country {
        position: absolute;
        margin-left: auto;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}

.no-options-drop {
    padding: 0.5rem 1rem;
}
</style>

<style src="@vueform/multiselect/themes/default.css"></style>

<style lang="scss">
.client-select,
.city-select {
    .multiselect-search {
        &:focus {
            background: $white;
        }
    }
}

.np-select {
    &.is-disabled {
        background: #cecece;
        .multiselect-placeholder {
            color: #b2b2b2;
        }
    }
}

.address-select {
    .multiselect-placeholder {
        color: $black;
    }
}
</style>
