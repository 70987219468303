<template>
    <MainLayout>
        <div class="balances-page">
            <div class="container">
                <div class="page-header">
                    <router-link :to="{ name: 'homePage' }" class="return-link">
                        <img src="@/assets/images/prev-page-arrow.svg" alt="" />
                    </router-link>
                    <div class="page-title text-h2 text-bold">Баланс</div>
                </div>

                <div class="balances-list">
                    <div v-for="account in accountsList" :key="account.id" class="account-item">
                        <div class="account-name text-h4">
                            {{ account.name }}
                        </div>
                        <div class="account-currency text-h4 text-bold">
                            {{ account.currency }}
                        </div>
                        <div class="account-balance text-h4" :class="balanceColor(account.balance)">
                            {{ account.balance }}
                        </div>
                    </div>

                    <Transition name="fade">
                        <LoaderComponent v-if="showLoader" />
                    </Transition>
                </div>
            </div>
        </div>
    </MainLayout>
</template>

<script>
import MainLayout from '@/layouts/MainLayout.vue'
import LoaderComponent from '@/components/LoaderComponent.vue'
import fetchWrapper from '@/helpers/fetch-wrapper.js'

export default {
    name: 'BalancesView',
    components: { LoaderComponent, MainLayout },
    data() {
        return {
            accountsList: [],
            showLoader: true
        }
    },

    mounted() {
        this.loadBalances()
    },
    methods: {
        async loadBalances() {
            await fetchWrapper
                .post(`${import.meta.env.VITE_FINANCE_URL}/driver/get-accounts`)
                .then((response) => {
                    this.accountsList = response.data
                })
                .finally(() => {
                    this.showLoader = false
                })
        },

        balanceColor(balanceStr) {
            const parts = balanceStr.split(' ')

            return parts[1].startsWith('-') ? 'negative' : 'positive'
        }
    }
}
</script>

<style scoped lang="scss">
.balances-page {
    padding: 1.5rem 0;

    .page-header {
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;

        .return-link {
            display: flex;
            margin-right: 1rem;
        }
    }

    .balances-list {
        position: relative;
    }

    .account-item {
        display: flex;
        align-items: center;
        padding: 1rem;
        border-radius: 0.625rem;
        margin-bottom: 0.625rem;
        background: $grey-light;
    }

    .account-name {
        margin-right: auto;
    }

    .account-currency {
        width: 3rem;
        min-width: 3rem;
        margin: 0 1.25rem;
    }

    .account-balance {
        width: 6.25rem;
        min-width: 6.25rem;
        text-align: right;
        white-space: nowrap;

        &.positive {
            color: $green;
        }

        &.negative {
            color: $red;
        }
    }
}
</style>
