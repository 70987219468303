<template>
    <GDialog v-model="show" max-width="500" border-radius="10" @update:model-value="hideModal">
        <div class="payment-modal">
            <div class="modal-title text-h1 text-bold">Оплата</div>
            <div class="modal-body">
                <div class="select-wrap">
                    <Multiselect
                        ref="accountSelect"
                        placeholder="Виберіть рахунок"
                        value-prop="id"
                        class="account-select"
                        :delay="500"
                        :resolve-on-load="true"
                        :options="accountsList"
                        :close-on-select="true"
                        @select="(item, option) => handleAccountSelect(option)"
                        @clear="handleAccountClear"
                    >
                        <template #caret>
                            <CollapseToggler :opened="false" />
                        </template>

                        <template #singlelabel="{ value }">
                            <div class="value-text">
                                <span class="text-h3">{{ value.name }}, {{ value.currency }}</span>
                            </div>
                        </template>
                        <template #option="{ option }">
                            <div class="option-text city-option-text">
                                <span class="text-h3">
                                    {{ option.name }}, {{ option.currency }}
                                </span>
                            </div>
                        </template>

                        <template #nooptions>
                            <div class="no-options-drop text-h5">Введіть для пошуку</div>
                        </template>
                    </Multiselect>
                </div>

                <template v-if="transactionForm.main_financial_account_id">
                    <div class="row">
                        <div class="col-12">
                            <InputComponent
                                v-model="transactionForm.original_amount"
                                label="Сума у вибраній валюті"
                                type="text"
                                :mask="{
                                    number: true,
                                    precision: 2
                                }"
                                :required="true"
                            />
                        </div>
                        <div class="col-7">
                            <div class="select-wrap">
                                <div class="select-label text-h5 text-grey">Валюта</div>
                                <Multiselect
                                    ref="originalCurrencySelect"
                                    v-model="transactionForm.original_currency"
                                    class="original-currency-select"
                                    placeholder="Валюта"
                                    value-prop="code"
                                    track-by="name"
                                    :options="currenciesList"
                                    :resolve-on-load="true"
                                    :close-on-select="true"
                                    :searchable="true"
                                    :disabled="formFields.disabled.includes('original_currency')"
                                >
                                    <template #caret>
                                        <CollapseToggler :opened="false" />
                                    </template>

                                    <template #singlelabel="{ value }">
                                        <div class="value-text">
                                            <span class="text-h3">
                                                {{ value.code }}, {{ value.symbol }}
                                            </span>
                                        </div>
                                    </template>
                                    <template #option="{ option }">
                                        <div class="option-text">
                                            <span class="text-h3">
                                                {{ option.code }}, {{ option.symbol }}
                                            </span>
                                        </div>
                                    </template>
                                </Multiselect>
                            </div>
                        </div>
                        <div
                            v-if="!formFields.hidden.includes('original_exchange_rate')"
                            class="col-5"
                        >
                            <InputComponent
                                v-model="transactionForm.original_exchange_rate"
                                label="Курс"
                                type="text"
                                :mask="{
                                    number: true,
                                    precision: 8
                                }"
                                :required="true"
                                :disabled="formFields.disabled.includes('original_exchange_rate')"
                            />
                        </div>
                    </div>

                    <template v-if="formFields.visible.includes('workspace_currency_amount')">
                        <div class="divider">
                            <div class="line"></div>
                            <div class="workspace-amount text-h5 text-grey">
                                {{ workspaceCurrency.code }}
                                {{ transactionForm.workspace_currency_amount }}
                            </div>
                            <div class="line"></div>
                        </div>
                    </template>

                    <div class="row">
                        <div v-if="!formFields.hidden.includes('account_currency')" class="col-7">
                            <div class="select-wrap">
                                <div class="select-label text-h5 text-grey">Валюта рахунку</div>
                                <Multiselect
                                    ref="accountCurrencySelect"
                                    v-model="transactionForm.account_currency"
                                    class="account-currency-select"
                                    placeholder="Валюта"
                                    value-prop="code"
                                    track-by="name"
                                    :options="currenciesList"
                                    :resolve-on-load="true"
                                    :close-on-select="true"
                                    :searchable="true"
                                    :disabled="formFields.disabled.includes('account_currency')"
                                >
                                    <template #caret>
                                        <CollapseToggler :opened="false" />
                                    </template>

                                    <template #singlelabel="{ value }">
                                        <div class="value-text">
                                            <span class="text-h3">
                                                {{ value.code }}, {{ value.symbol }}
                                            </span>
                                        </div>
                                    </template>
                                    <template #option="{ option }">
                                        <div class="option-text">
                                            <span class="text-h3">
                                                {{ option.code }}, {{ option.symbol }}
                                            </span>
                                        </div>
                                    </template>
                                </Multiselect>
                            </div>
                        </div>
                        <div
                            v-if="!formFields.hidden.includes('account_exchange_rate')"
                            class="col-5"
                        >
                            <InputComponent
                                v-model="transactionForm.account_exchange_rate"
                                label="Курс"
                                type="text"
                                :mask="{
                                    number: true,
                                    precision: 8
                                }"
                                :required="true"
                                :disabled="formFields.disabled.includes('account_exchange_rate')"
                            />
                        </div>
                        <div v-if="!formFields.hidden.includes('account_amount')" class="col-12">
                            <InputComponent
                                v-model="transactionForm.account_amount"
                                label="Сума у валюті рахунку"
                                type="text"
                                :mask="{
                                    number: true,
                                    precision: 2
                                }"
                                :required="true"
                                :disabled="formFields.disabled.includes('account_amount')"
                            />
                        </div>
                    </div>
                </template>

                <ImagesComponent
                    title-class="text-h3 text-bold"
                    form-data-key="images[]"
                    :block-data="imagesPreview"
                    block-title="Фото"
                    @load-image="loadImage"
                />

                <InputComponent
                    v-model="transactionForm.comment"
                    type="text"
                    label="Коментар"
                    placeholder="Коментар"
                />
            </div>
            <div class="modal-buttons">
                <ButtonComponent
                    class="cancel-btn"
                    btn-style="grey"
                    text="Скасувати"
                    @click="hideModal"
                />

                <ButtonComponent
                    btn-style="gradient"
                    class="text-h2"
                    text="Додати оплату"
                    @click="submitTransactionCreate"
                />
            </div>

            <ImageDeleteModal modal-title="Видалити фото?" @submit-delete="submitImageDelete" />
            <LoaderComponent v-if="isFormProcessing" />
        </div>
    </GDialog>
</template>

<script>
import ImageDeleteModal from '@/components/modals/ImageDeleteModal.vue'
import ButtonComponent from '@/components/ButtonComponent.vue'
import ImagesComponent from '@/components/ImagesComponent.vue'
import fetchWrapper from '@/helpers/fetch-wrapper.js'
import CollapseToggler from '@/components/CollapseToggler.vue'
import Multiselect from '@vueform/multiselect'
import InputComponent from '@/components/form/InputComponent.vue'
import { useActiveRouteStore } from '@/stores/index.js'
import LoaderComponent from '@/components/LoaderComponent.vue'

export default {
    name: 'PaymentModal',
    components: {
        LoaderComponent,
        CollapseToggler,
        ImagesComponent,
        ButtonComponent,
        ImageDeleteModal,
        InputComponent,
        Multiselect
    },
    props: {
        entity_id: {
            type: [Number, null],
            required: true
        },
        entity_type: {
            type: [String, null],
            required: true
        }
    },
    emits: ['transaction-added'],
    setup() {
        const activeRouteStore = useActiveRouteStore()

        return { activeRouteStore }
    },
    data() {
        return {
            transactionForm: {
                entity_id: null,
                entity_type: null,
                main_financial_account_id: null,
                main_financial_account_name: null,
                main_financial_account_currency: null,
                original_amount: null,
                original_currency: null,
                original_exchange_rate: null,
                workspace_currency_amount: null,
                account_amount: null,
                account_currency: null,
                account_exchange_rate: null,
                documents: [],
                comment: null
            },
            show: false,
            workspaceCurrency: null,
            currenciesList: null,
            imagesPreview: [],
            formFields: {
                visible: [],
                hidden: [],
                disabled: [],
                to_clear: [],
                titles: {
                    first: null,
                    second: null,
                    firstWithAccountName: false,
                    secondWithAccountName: false
                }
            },
            exchangeRates: [],
            defaultCalculationStrategy: true,
            suppressAmountWatch: false,
            suppressCurrencyWatch: false,
            transactionCase: null,
            isFormProcessing: false
        }
    },
    computed: {
        formattedOriginalAmount() {
            return parseFloat(this.transactionForm.original_amount?.toString().replace(',', '.'))
        },

        formattedOriginalExchangeRate() {
            return parseFloat(
                this.transactionForm.original_exchange_rate?.toString().replace(',', '.')
            )
        },

        formattedWorkspaceAmount() {
            return parseFloat(
                this.transactionForm.workspace_currency_amount?.toString().replace(',', '.')
            )
        },

        formattedAccountAmount() {
            return parseFloat(this.transactionForm.account_amount?.toString().replace(',', '.'))
        },

        formattedAccountExchangeRate() {
            return parseFloat(
                this.transactionForm.account_exchange_rate?.toString().replace(',', '.')
            )
        }
    },
    watch: {
        'transactionForm.original_currency': function () {
            this.updateFormFields()
        },
        'transactionForm.account_currency': function () {
            this.updateFormFields()
        },
        'transactionForm.original_amount': function () {
            this.handleInputWatch('original_amount')
        },
        'transactionForm.account_amount': function () {
            this.handleInputWatch('account_amount')
        },
        'transactionForm.original_exchange_rate': function () {
            this.handleInputWatch('original_exchange_rate')
        },
        'transactionForm.account_exchange_rate': function () {
            this.handleInputWatch('account_exchange_rate')
        }
    },
    mounted() {
        this.loadCurrencies()
    },
    methods: {
        async loadCurrencies() {
            await fetchWrapper
                .post(`${import.meta.env.VITE_FINANCE_URL}/currency/get-current-currency`, {})
                .then((response) => {
                    this.workspaceCurrency = response.data
                })

            await fetchWrapper
                .post(`${import.meta.env.VITE_FINANCE_URL}/currency/get-currencies`, {})
                .then((response) => {
                    this.currenciesList = response.data
                })
        },

        async accountsList() {
            const options = await fetchWrapper.post(
                `${import.meta.env.VITE_FINANCE_URL}/driver/get-accounts`
            )

            if (!options.data.length) {
                return
            }

            return options.data
        },

        handleAccountSelect(item) {
            this.transactionForm.main_financial_account_id = item.id
            this.transactionForm.main_financial_account_name = item.name
            this.transactionForm.main_financial_account_currency = item.currency

            this.$nextTick(() => {
                this.$refs.originalCurrencySelect?.select(item.currency)
            })
        },

        handleAccountClear() {
            this.transactionForm.main_financial_account_id = null
            this.transactionForm.main_financial_account_name = null
            this.transactionForm.main_financial_account_currency = null

            this.$refs.originalCurrencySelect?.clear()
        },

        async updateFormFields(loadExchangeRate = true, withClear = true) {
            if (this.suppressCurrencyWatch || this.suppressAmountWatch) {
                return
            }

            this.suppressCurrencyWatch = true
            this.suppressAmountWatch = true

            const { original_currency, main_financial_account_currency } = this.transactionForm

            if (main_financial_account_currency === this.workspaceCurrency.code) {
                if (original_currency === this.workspaceCurrency.code) {
                    console.log('Case 1')
                    this.transactionCase = 1

                    this.formFields = {
                        visible: [],
                        hidden: [
                            'original_exchange_rate',
                            'account_amount',
                            'account_currency',
                            'account_exchange_rate'
                        ],
                        disabled: [
                            'original_exchange_rate',
                            'account_amount',
                            'account_currency',
                            'account_exchange_rate'
                        ],
                        to_clear: [
                            'workspace_currency_amount',
                            'account_amount',
                            'account_currency',
                            'account_exchange_rate'
                        ]
                    }

                    if (withClear) {
                        this.clearSomeFields()
                    }

                    this.exchangeRates = [
                        {
                            sameCurrency: true,
                            resultField: 'original_exchange_rate'
                        },
                        {
                            sameCurrency: true,
                            resultField: 'account_exchange_rate'
                        }
                    ]

                    this.defaultCalculationStrategy = true
                } else {
                    console.log('Case 2')
                    this.transactionCase = 2

                    this.formFields = {
                        visible: ['workspace_currency_amount'],
                        hidden: ['account_amount', 'account_currency', 'account_exchange_rate'],
                        disabled: ['account_amount', 'account_currency', 'account_exchange_rate'],
                        to_clear: ['account_amount', 'account_currency', 'account_exchange_rate']
                    }

                    if (withClear) {
                        this.clearSomeFields()
                    }

                    this.exchangeRates = [
                        {
                            firstCurrency: this.transactionForm.original_currency,
                            secondCurrency: this.workspaceCurrency.code,
                            resultField: 'original_exchange_rate'
                        }
                    ]

                    this.defaultCalculationStrategy = true
                }
            } else {
                if (original_currency === this.workspaceCurrency.code) {
                    console.log('Case 3')
                    this.transactionCase = 3

                    this.formFields = {
                        visible: [],
                        hidden: ['original_exchange_rate'],
                        disabled: ['original_exchange_rate', 'account_currency'],
                        to_clear: ['workspace_currency_amount']
                    }

                    if (withClear) {
                        this.clearSomeFields()
                    }

                    if (this.transactionForm.main_financial_account_id) {
                        this.$nextTick().then(() => {
                            this.$refs.accountCurrencySelect.select(
                                this.transactionForm.main_financial_account_currency
                            )
                        })
                    }

                    this.exchangeRates = [
                        {
                            sameCurrency: true,
                            resultField: 'original_exchange_rate'
                        },
                        {
                            firstCurrency: this.workspaceCurrency.code,
                            secondCurrency: this.transactionForm.original_currency,
                            resultField: 'account_exchange_rate'
                        }
                    ]

                    this.defaultCalculationStrategy = true
                } else {
                    if (original_currency === main_financial_account_currency) {
                        console.log('Case 4')
                        this.transactionCase = 4

                        this.formFields = {
                            visible: ['workspace_currency_amount'],
                            hidden: ['account_amount', 'account_currency', 'account_exchange_rate'],
                            disabled: [
                                'account_amount',
                                'account_currency',
                                'account_exchange_rate'
                            ],
                            to_clear: [
                                'account_amount',
                                'account_currency',
                                'account_exchange_rate'
                            ]
                        }

                        if (withClear) {
                            this.clearSomeFields()
                        }

                        this.exchangeRates = [
                            {
                                firstCurrency: this.transactionForm.original_currency,
                                secondCurrency: this.workspaceCurrency.code,
                                resultField: 'original_exchange_rate'
                            }
                        ]

                        this.defaultCalculationStrategy = false
                    } else {
                        console.log('Case 5')
                        this.transactionCase = 5

                        this.formFields = {
                            visible: ['workspace_currency_amount'],
                            hidden: [],
                            disabled: ['account_currency'],
                            to_clear: []
                        }

                        if (withClear) {
                            this.clearSomeFields()
                        }

                        if (this.transactionForm.main_financial_account_id) {
                            this.$nextTick().then(() => {
                                this.$refs.accountCurrencySelect.select(
                                    this.transactionForm.main_financial_account_currency
                                )
                            })
                        }

                        this.exchangeRates = [
                            {
                                firstCurrency: this.transactionForm.original_currency,
                                secondCurrency: this.workspaceCurrency.code,
                                resultField: 'original_exchange_rate'
                            },
                            {
                                firstCurrency: this.workspaceCurrency.code,
                                secondCurrency:
                                    this.transactionForm.main_financial_account_currency,
                                resultField: 'account_exchange_rate'
                            }
                        ]

                        this.defaultCalculationStrategy = true
                    }
                }
            }

            this.suppressCurrencyWatch = false
            this.suppressAmountWatch = false

            if (loadExchangeRate) {
                await this.getExchangeRate()
            }
        },

        clearSomeFields() {
            this.formFields.to_clear.map((item) => {
                this.transactionForm[item] = null
            })
        },

        async getExchangeRate() {
            this.exchangeRates.map((item) => {
                if (item.sameCurrency) {
                    this.transactionForm[item.resultField] = 1

                    return
                }

                if (
                    !item.firstCurrency ||
                    !item.secondCurrency ||
                    item.firstCurrency === item.secondCurrency
                ) {
                    return
                }

                fetchWrapper
                    .post(`${import.meta.env.VITE_FINANCE_URL}/currency/get-exchange-rate`, {
                        first_currency_code: item.firstCurrency,
                        second_currency_code: item.secondCurrency
                    })
                    .then((response) => {
                        this.transactionForm[item.resultField] = response.data.exchange_rate
                    })
            })
        },

        handleInputWatch(field) {
            if (!this.suppressAmountWatch) {
                this.suppressAmountWatch = true
                this.calculateAmount(field)

                this.$nextTick(() => {
                    this.suppressAmountWatch = false
                })
            }
        },

        calculateAmount(inputTrigger) {
            const nullFieldsCount = [
                'original_amount',
                'original_exchange_rate',
                'account_amount',
                'account_exchange_rate'
            ].filter((field) => this.transactionForm[field] === null).length

            const nullFieldsAllowed = this.exchangeRates.length === 2 ? 2 : 3

            if (nullFieldsCount >= nullFieldsAllowed) {
                console.log('Not enough data to perform calculation')

                return
            }

            let forwardCalculation =
                inputTrigger === 'original_amount' ||
                inputTrigger === 'original_exchange_rate' ||
                inputTrigger === 'account_exchange_rate'

            if (this.defaultCalculationStrategy) {
                if (forwardCalculation) {
                    let originalAmountInCents = this.formattedOriginalAmount * 100
                    let workspaceAmountInCents =
                        originalAmountInCents * this.formattedOriginalExchangeRate

                    this.transactionForm.workspace_currency_amount = isNaN(workspaceAmountInCents)
                        ? 0
                        : Math.round(workspaceAmountInCents) / 100

                    const accountAmount = (
                        this.formattedWorkspaceAmount * this.formattedAccountExchangeRate
                    ).toFixed(2)

                    this.transactionForm.account_amount =
                        parseFloat(accountAmount) !== 0 ? parseFloat(accountAmount) : null
                } else {
                    let accountAmountInCents = this.formattedAccountAmount * 100
                    let originalAmount

                    if (this.transactionCase === 5) {
                        originalAmount = (
                            this.formattedAccountAmount /
                            this.formattedAccountExchangeRate /
                            this.formattedOriginalExchangeRate
                        ).toFixed(2)
                    } else {
                        originalAmount = (
                            this.formattedAccountAmount / this.formattedAccountExchangeRate
                        ).toFixed(2)
                    }

                    this.transactionForm.original_amount =
                        parseFloat(originalAmount) !== 0 ? parseFloat(originalAmount) : null

                    if (this.transactionCase === 5) {
                        let originalAmountInCents = this.formattedOriginalAmount * 100
                        let workspaceAmountInCents =
                            originalAmountInCents * this.formattedOriginalExchangeRate

                        this.transactionForm.workspace_currency_amount = isNaN(
                            workspaceAmountInCents
                        )
                            ? 0
                            : Math.round(workspaceAmountInCents) / 100
                    } else {
                        let workspaceAmountInCents =
                            accountAmountInCents * this.formattedAccountExchangeRate

                        this.transactionForm.workspace_currency_amount = isNaN(
                            workspaceAmountInCents
                        )
                            ? 0
                            : Math.round(workspaceAmountInCents) / 100
                    }
                }
            } else {
                if (forwardCalculation) {
                    let originalAmountInCents = Math.round(this.formattedOriginalAmount * 100)

                    let workspaceAmountInCents =
                        originalAmountInCents * this.formattedOriginalExchangeRate

                    this.transactionForm.workspace_currency_amount = isNaN(workspaceAmountInCents)
                        ? 0
                        : Math.round(workspaceAmountInCents) / 100

                    this.transactionForm.account_amount = this.formattedOriginalAmount
                } else if (this.transactionCase !== 4) {
                    let accountAmountInCents = Math.round(this.formattedAccountAmount * 100)
                    let workspaceAmountInCents =
                        accountAmountInCents * this.formattedAccountExchangeRate

                    this.transactionForm.workspace_currency_amount = isNaN(workspaceAmountInCents)
                        ? 0
                        : Math.round(workspaceAmountInCents) / 100

                    this.transactionForm.original_amount = this.formattedAccountAmount
                }
            }
        },

        loadImage(formData) {
            this.convertFilesToURLs(formData)
        },

        convertFilesToURLs(formData) {
            return new Promise(() => {
                const fileURLs = []
                const files = []
                const promises = []

                formData.getAll('images[]').forEach((file) => {
                    const reader = new FileReader()

                    const promise = new Promise((resolveReader, rejectReader) => {
                        reader.onload = function (event) {
                            fileURLs.push({ url: event.target.result })
                            files.push(file)
                            resolveReader()
                        }
                        reader.onerror = function (error) {
                            rejectReader(error)
                        }
                    })

                    reader.readAsDataURL(file)
                    promises.push(promise)
                })

                Promise.all(promises)
                    .then(() => {
                        this.imagesPreview.push(...fileURLs)
                        this.imagesPreview.forEach((image, index) => {
                            image.id = index
                        })

                        const transformedFiles = files.map((file) => ({
                            name: file.name,
                            file: file
                        }))

                        this.transactionForm.documents.push(...transformedFiles)
                    })
                    .catch((error) => {
                        this.$toast.error(error)
                    })
            })
        },

        submitImageDelete(itemIndex) {
            this.imagesPreview.splice(itemIndex, 1)
            this.transactionForm.documents.splice(itemIndex, 1)
            this.imagesPreview.forEach((image, index) => {
                image.id = index
            })
        },

        showModal() {
            this.show = true
        },

        hideModal() {
            this.show = false

            this.$refs.accountSelect.clear()

            this.transactionForm = {
                entity_id: null,
                entity_type: null,
                main_financial_account_id: null,
                main_financial_account_name: null,
                main_financial_account_currency: null,
                original_amount: null,
                original_currency: null,
                original_exchange_rate: null,
                workspace_currency_amount: null,
                account_amount: null,
                account_currency: null,
                account_exchange_rate: null,
                documents: [],
                comment: null
            }
        },

        async submitTransactionCreate() {
            this.isFormProcessing = true
            this.transactionForm.entity_id = this.entity_id
            this.transactionForm.entity_type = this.entity_type

            if (!this.activeRouteStore.routeId) {
                await this.activeRouteStore.getActiveRouteId()
            }

            const properties = [
                { key: 'route_id', value: this.activeRouteStore.routeId },
                { key: 'route_name', value: this.activeRouteStore.routeName }
            ]

            fetchWrapper
                .post(
                    `${import.meta.env.VITE_FINANCE_URL}/route-transactions/create`,
                    {
                        ...this.transactionForm,
                        properties
                    },
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                )
                .then(() => {
                    this.hideModal()
                    this.$emit('transaction-added')
                    this.$toast.success('Оплату додано')
                })
                .finally(() => {
                    this.isFormProcessing = false
                })
        }
    }
}
</script>

<style scoped lang="scss">
.payment-modal {
    padding: 2rem 0.5rem 2.5rem 1.25rem;

    .modal-title {
        padding-right: 0.75rem;
        margin-bottom: 1.25rem;
    }

    .modal-body {
        max-height: calc(90vh - 11rem);
        padding: 0 0.75rem 0 0;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .divider {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        .line {
            height: 1px;
            background: $grey-light;
            flex-grow: 1;
        }

        .workspace-amount {
            margin: 0 1rem;
        }
    }

    .images-component {
        margin-bottom: 2rem;
    }

    .value-text,
    .option-text {
        display: flex;
        align-items: center;

        img {
            margin-right: 0.25rem;
        }
    }

    .no-options-drop {
        padding: 0.5rem 1rem;
    }

    .modal-buttons {
        padding-right: 0.75rem;
    }
}

.loader-overlay {
    opacity: 0.7;
}
</style>
