<template>
    <div class="transaction-component" :class="`type-${transactionInfo.type}`">
        <div class="left-col">
            <div class="date text-h5">
                {{ transactionInfo.date }}
            </div>
            <div class="main-account text-h4 text-bold">
                {{ transactionInfo.main_financial_account?.name }}
            </div>
            <div
                v-if="transactionInfo.type === 'transfer_account'"
                class="additional-account text-h5 text-grey"
            >
                {{ transactionInfo.additional_financial_account?.name }}
            </div>
            <div class="bottom-row">
                <div class="author text-h5">
                    {{ transactionInfo.author }}
                </div>
                <div class="category">
                    <div
                        v-if="transactionInfo.type === 'transfer_account'"
                        class="transaction-category transfer-category text-h5"
                    >
                        Переказ
                    </div>
                    <div
                        v-else
                        class="transaction-category text-h5"
                        :class="{
                            'income-category': transactionInfo.type === 'income',
                            'expense-category': transactionInfo.type === 'expense'
                        }"
                    >
                        {{ transactionInfo.category?.name }}
                    </div>
                </div>
            </div>
        </div>
        <div class="right-col">
            <div class="edit">
                <router-link
                    v-if="transactionInfo.permissions?.edit"
                    :to="{
                        name: 'editTransactionPage',
                        params: {
                            id: transactionInfo.id
                        }
                    }"
                    class="edit-link"
                >
                    <img src="@/assets/images/edit-icon.svg" alt="" />
                </router-link>
            </div>
            <div class="amount">
                <div class="main-amount text-h4 text-bold" :class="`type-${transactionInfo.type}`">
                    {{ transactionInfo.amount }}
                </div>
                <div
                    v-if="
                        new Set([
                            transactionInfo.account_amount,
                            transactionInfo.amount_in_main_currency,
                            transactionInfo.amount
                        ]).size > 1
                    "
                    class="other-amounts"
                >
                    <div class="account-amount text-h5">
                        {{ transactionInfo.account_amount }}
                    </div>
                    <div class="workspace-amount text-h5 text-grey">
                        {{ transactionInfo.amount_in_main_currency }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TransactionComponent',
    props: {
        transactionInfo: {
            type: Object,
            required: true
        }
    }
}
</script>

<style scoped lang="scss">
.transaction-component {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0.75rem;
    margin-bottom: 1rem;
    border-radius: 1rem;

    &.type-income {
        background: linear-gradient(270deg, #fff 0%, #fff 69.67%, #d9f5e1 100%);

        .main-amount {
            color: $green;
        }
    }

    &.type-expense {
        background: linear-gradient(270deg, #fff 0%, #fff 69.67%, #f6e0e0 100%);

        .main-amount {
            color: $red;
        }
    }

    &.type-transfer_account {
        background: linear-gradient(270deg, #fff 0%, #fff 69.67%, #f8efde 100%);

        .main-amount {
            color: $yellow;
        }
    }

    .date {
        margin-bottom: 1rem;
    }

    .main-account {
        margin-bottom: 0.25rem;
    }

    .bottom-row {
        display: flex;
        margin-top: 1rem;

        .author {
            margin-right: 2rem;
        }
    }

    .transaction-category {
        padding: 0.125rem 0.5rem;
        background: $grey-light;
        border-radius: 0.5rem;
        width: fit-content;
        text-align: center;

        &.income-category {
            color: $green;
        }

        &.expense-category {
            color: $red;
        }

        &.transfer-category {
            color: $yellow;
        }
    }

    .right-col {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        padding-left: 1.5rem;
    }

    .edit {
        height: 1.25rem;
        margin-bottom: 0.75rem;

        .edit-link {
            display: flex;
            width: fit-content;
        }
    }

    .main-amount,
    .account-amount,
    .workspace-amount {
        margin-bottom: 0.25rem;
        text-align: right;
    }
}
</style>
