<template>
    <div class="breadcrumbs">
        <div v-if="breadcrumbs.length" class="prev-breadcrumb" @click="moveToPrevBreadcrumb">
            <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M5.33333 3.33398L2 6.66732L5.33333 10.0007"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M2 6.66797L7.33333 6.66797C11.0153 6.66797 14 9.65264 14 13.3346L14 14.0013"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </div>
        <div
            v-for="(item, index) in breadcrumbs"
            :key="index"
            class="breadcrumb-item text-h5 text-grey"
            :class="{ active: index === breadcrumbs.length - 1 }"
            @click="moveToBreadcrumb(item.slug, item.id)"
        >
            {{ item.name }}
            <span class="separator">{{ index !== breadcrumbs.length - 1 ? '/' : '' }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AccountsBreadcrumbs',
    props: {
        breadcrumbs: {
            type: Array,
            default: () => []
        }
    },
    emits: ['step-back', 'move-to-breadcrumb', 'move-to-breadcrumb-id'],
    methods: {
        moveToPrevBreadcrumb() {
            this.$emit('step-back')
        },

        moveToBreadcrumb(slug, id) {
            this.$emit('move-to-breadcrumb', slug)
            this.$emit('move-to-breadcrumb-id', id)
        }
    }
}
</script>

<style scoped lang="scss">
.breadcrumbs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 0.625rem;
    margin-bottom: 1.25rem;
    border-bottom: 1px solid $grey-light;

    .prev-breadcrumb {
        margin-right: 0.5rem;

        &:hover {
            cursor: pointer;

            svg {
                path {
                    stroke: $primary;
                }
            }
        }
    }

    .breadcrumb-item {
        display: flex;
        transition: all ease-in-out 0.3s;
        text-decoration: none;

        &.active {
            color: $black;
            pointer-events: none;
        }

        &:not(.active):hover {
            cursor: pointer;
            color: $primary;
        }

        .separator {
            margin: 0 0.5rem;
            color: $grey-light;
        }
    }
}
</style>
