<template>
    <GDialog v-model="show" max-width="500" border-radius="10" @update:model-value="hideModal">
        <div class="finish-route-submit-modal">
            <div class="modal-title text-h1 text-bold text-center">
                Будь ласка, звірте <br />
                баланси рахунків
            </div>
            <div class="modal-description text-h2 text-center">перед завершенням маршруту</div>
            <div class="modal-body">
                <div v-for="account in accountsList" :key="account.id" class="account-item">
                    <div class="account-name text-h4">
                        {{ account.name }}
                    </div>
                    <div class="account-currency text-h4 text-bold">
                        {{ account.currency }}
                    </div>
                    <div class="account-balance text-h4" :class="balanceColor(account.balance)">
                        {{ account.balance }}
                    </div>
                </div>

                <Transition name="fade">
                    <LoaderComponent v-if="showLoader" />
                </Transition>
            </div>
            <div class="modal-buttons">
                <ButtonComponent
                    class="cancel-btn text-h4"
                    btn-style="bordered"
                    text="Транзакції"
                    @click="openTransactionPage"
                />

                <ButtonComponent
                    btn-style="gradient"
                    class="submit-btn text-h4"
                    text="Завершити маршрут"
                    @click="submitAction"
                />
            </div>
        </div>
    </GDialog>
</template>

<script>
import ButtonComponent from '@/components/ButtonComponent.vue'
import fetchWrapper from '@/helpers/fetch-wrapper.js'
import LoaderComponent from '@/components/LoaderComponent.vue'

export default {
    name: 'FinishRouteSubmitModal',
    components: { LoaderComponent, ButtonComponent },
    emits: ['submit-action'],
    data() {
        return {
            accountsList: [],
            show: false,
            showLoader: false
        }
    },
    watch: {
        show: {
            handler(newValue) {
                if (newValue) {
                    this.loadBalances()
                }
            }
        }
    },
    methods: {
        showModal() {
            this.show = true
        },

        async loadBalances() {
            this.showLoader = true

            await fetchWrapper
                .post(`${import.meta.env.VITE_FINANCE_URL}/driver/get-accounts`)
                .then((response) => {
                    this.accountsList = response.data
                })
                .finally(() => {
                    this.showLoader = false
                })
        },

        balanceColor(balanceStr) {
            const parts = balanceStr.split(' ')

            return parts[1].startsWith('-') ? 'negative' : 'positive'
        },

        openTransactionPage() {
            this.hideModal()
            this.$router.push({ name: 'transactionsPage' })
        },

        submitAction() {
            this.$emit('submit-action')
            this.hideModal()
        },

        hideModal() {
            this.show = false
            this.accountsList = []
        }
    }
}
</script>

<style scoped lang="scss">
.finish-route-submit-modal {
    padding: 2.5rem 1rem 3rem;

    .modal-title {
        margin-bottom: 0.625rem;
    }

    .modal-description {
        margin-bottom: 0;
    }

    .modal-body {
        position: relative;
        max-height: calc(90vh - 11rem);
        padding: 2rem 0.75rem 3rem 0;
        overflow-y: auto;
        overflow-x: hidden;

        .account-item {
            display: flex;
            align-items: center;
            padding: 1rem;
            border-radius: 0.625rem;
            margin-bottom: 0.625rem;
            background: $grey-light;
        }

        .account-name {
            margin-right: auto;
        }

        .account-currency {
            width: 3rem;
            min-width: 3rem;
            margin: 0 1.25rem;
        }

        .account-balance {
            width: 6.25rem;
            min-width: 6.25rem;
            text-align: right;
            white-space: nowrap;

            &.positive {
                color: $green;
            }

            &.negative {
                color: $red;
            }
        }
    }

    .modal-buttons {
        .cancel-btn,
        .submit-btn {
            min-height: 2.5rem;
        }

        .submit-btn {
            white-space: nowrap;
        }
    }
}
</style>
