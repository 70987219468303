<template>
    <GDialog
        :model-value="show"
        :width="'28rem'"
        border-radius="10"
        @update:model-value="cancelAction"
    >
        <div class="account-select-modal">
            <div class="modal-body">
                <div class="modal-title text-bold">Виберіть рахунок</div>
                <div class="loader-wrap">
                    <AccountsBreadcrumbs
                        :breadcrumbs="breadcrumbs"
                        @move-to-breadcrumb-id="openGroup"
                        @step-back="moveToPreviousBreadcrumb"
                    />
                    <div class="items-list custom-scrollbar">
                        <AccountsGroup
                            v-for="item in accountsGroups"
                            :key="item.id"
                            :group-data="item"
                            @open-group="openGroup(item.id)"
                        />
                        <div
                            v-if="
                                !accountsGroups?.length && !accounts?.length && breadcrumbs?.length
                            "
                            class="text-h5 text-center"
                        >
                            Це група останнього рівня вкладеності
                        </div>
                        <AccountItem
                            v-for="item in accounts"
                            :key="item.id"
                            :account-data="item"
                            :show-balance="true"
                            @click="selectAccount(item)"
                        />
                    </div>
                    <div
                        v-if="!accountsGroups.length && !accounts.length && !loadingData"
                        class="empty-list text-h6 text-center"
                    >
                        Немає жодного привʼязаного рахунку для вашого користувача
                    </div>
                    <LoaderComponent v-if="loadingData" />
                </div>
                <div class="buttons-row">
                    <ButtonComponent
                        class="cancel-btn"
                        btn-style="grey"
                        text="Скасувати"
                        @click.prevent="cancelAction"
                    />
                </div>
            </div>
        </div>
    </GDialog>
</template>

<script>
import AccountsGroup from '@/components/account/AccountsGroup.vue'
import AccountsBreadcrumbs from '@/components/account/AccountsBreadcrumbs.vue'
import AccountItem from '@/components/account/AccountItem.vue'
import LoaderComponent from '@/components/LoaderComponent.vue'
import fetchWrapper from '@/helpers/fetch-wrapper.js'
import ButtonComponent from '@/components/ButtonComponent.vue'

export default {
    name: 'AccountSelectModal',
    components: {
        ButtonComponent,
        LoaderComponent,
        AccountItem,
        AccountsBreadcrumbs,
        AccountsGroup
    },
    props: {
        show: {
            type: Boolean,
            default: false
        }
    },
    emits: ['select-account', 'cancel-action'],
    data() {
        return {
            breadcrumbs: [],
            accountsGroups: [],
            accounts: [],
            loadingData: false
        }
    },
    watch: {
        show: {
            handler(newValue) {
                if (newValue) {
                    this.openGroup()
                }
            },
            immediate: true
        }
    },
    methods: {
        moveToPreviousBreadcrumb() {
            if (this.breadcrumbs.length > 1) {
                return this.openGroup(this.breadcrumbs[this.breadcrumbs.length - 2].id)
            } else {
                return this.openGroup()
            }
        },

        openGroup(id = null) {
            this.loadingData = true

            fetchWrapper
                .post(
                    `${import.meta.env.VITE_FINANCE_URL}/transactions/get-user-financial-accounts`,
                    { financial_account_group_id: id }
                )
                .then((response) => {
                    const { breadcrumbs, groups, accounts } = response.data

                    this.breadcrumbs = breadcrumbs
                    this.accountsGroups = groups
                    this.accounts = accounts
                })
                .catch((error) => {
                    const errorMessage =
                        error.response?.data?.message || error.message || 'An error occurred'

                    this.$toast.error(errorMessage)
                })
                .finally(() => {
                    this.loadingData = false
                })
        },

        selectAccount(account) {
            this.$emit('select-account', account)
            this.clearData()
        },

        cancelAction() {
            this.$emit('cancel-action')
            this.clearData()
        },

        clearData() {
            this.breadcrumbs = []
            this.accountsGroups = []
            this.accounts = []
        }
    }
}
</script>

<style scoped lang="scss">
.account-select-modal {
}

.loader-wrap {
    position: relative;
    padding: 0.5rem 0;
}

.items-list {
    max-height: 60vh;
    overflow-y: auto;
    padding-right: 0.5rem;
}

:deep(.account-item) {
    &:hover {
        background: $grey-light;
        cursor: pointer;

        .account-icon {
            path {
                fill: $primary;
            }
        }

        .account-name {
            color: $primary;
        }
    }
}

.buttons-row {
    display: flex;
    justify-content: space-between;
    margin-top: 1.25rem;

    .cancel-btn {
        width: 100%;
    }
}
</style>
