<template>
    <div class="group-item">
        <div class="group-link" @click="openGroup">
            <div class="group-icon">
                <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M1.5 8.5V8C1.5 7.60218 1.65804 7.22064 1.93934 6.93934C2.22064 6.65804 2.60218 6.5 3 6.5H13C13.3978 6.5 13.7794 6.65804 14.0607 6.93934C14.342 7.22064 14.5 7.60218 14.5 8V8.5M8.70667 4.20667L7.29333 2.79333C7.20048 2.70037 7.09022 2.62661 6.96886 2.57628C6.84749 2.52595 6.71739 2.50003 6.586 2.5H3C2.60218 2.5 2.22064 2.65804 1.93934 2.93934C1.65804 3.22064 1.5 3.60218 1.5 4V12C1.5 12.3978 1.65804 12.7794 1.93934 13.0607C2.22064 13.342 2.60218 13.5 3 13.5H13C13.3978 13.5 13.7794 13.342 14.0607 13.0607C14.342 12.7794 14.5 12.3978 14.5 12V6C14.5 5.60218 14.342 5.22064 14.0607 4.93934C13.7794 4.65804 13.3978 4.5 13 4.5H9.414C9.14887 4.49977 8.89402 4.39426 8.70667 4.20667Z"
                        stroke="black"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>
            </div>

            <div class="group-name text-h3">
                {{ groupData.name }}
            </div>
        </div>

        <div v-if="showBalance" class="group-balance text-h7 text-grey-100">
            {{ groupData.balance_in_main_currency }}
        </div>

        <template v-if="editable">
            <div class="edit-icon" @click="editGroup">
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M13.4742 2.74997C13.9546 2.26976 14.606 2 15.2852 2C15.9644 2 16.6158 2.26976 17.0962 2.74997L17.25 2.90375C17.7302 3.3841 18 4.03559 18 4.7148C18 5.39401 17.7302 6.04542 17.25 6.52577L6.89621 16.8796C6.79699 16.9788 6.67268 17.0492 6.53655 17.0832L2.96198 17.9769C2.69815 18.0428 2.41907 17.9655 2.22677 17.7732C2.03448 17.5809 1.95718 17.3018 2.02313 17.038L2.91677 13.4635C2.95081 13.3273 3.02119 13.203 3.12041 13.1038L12.0566 4.16763L13.4742 2.74997ZM12.6043 5.80968L4.36692 14.047L3.83824 16.1618L5.95297 15.6331L14.1903 7.39573L12.6043 5.80968ZM15.2852 6.30085L13.6991 4.7148L14.5689 3.845C14.5689 3.84503 14.569 3.84498 14.5689 3.845C14.7589 3.65513 15.0166 3.54839 15.2852 3.54839C15.5538 3.54839 15.8115 3.65508 16.0015 3.845L16.155 3.99855C16.3449 4.18853 16.4516 4.44617 16.4516 4.7148C16.4516 4.9834 16.345 5.241 16.1551 5.43097C16.155 5.431 16.1551 5.43095 16.1551 5.43097L15.2852 6.30085Z"
                        fill="black"
                    />
                </svg>
            </div>
            <div
                class="delete-icon"
                :class="{ disabled: !groupData.actions.can_delete }"
                @click="deleteGroup"
            >
                <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M8.4 5.2H11.6C11.6 4.77565 11.4314 4.36869 11.1314 4.06863C10.8313 3.76857 10.4243 3.6 10 3.6C9.57565 3.6 9.16869 3.76857 8.86863 4.06863C8.56857 4.36869 8.4 4.77565 8.4 5.2ZM6.8 5.2C6.8 4.35131 7.13714 3.53737 7.73726 2.93726C8.33737 2.33714 9.15131 2 10 2C10.8487 2 11.6626 2.33714 12.2627 2.93726C12.8629 3.53737 13.2 4.35131 13.2 5.2H17.2C17.4122 5.2 17.6157 5.28429 17.7657 5.43431C17.9157 5.58434 18 5.78783 18 6C18 6.21217 17.9157 6.41566 17.7657 6.56569C17.6157 6.71571 17.4122 6.8 17.2 6.8H16.4944L15.7856 15.072C15.7175 15.8708 15.352 16.6149 14.7615 17.1571C14.171 17.6993 13.3985 18.0001 12.5968 18H7.4032C6.60153 18.0001 5.82902 17.6993 5.23852 17.1571C4.64802 16.6149 4.28254 15.8708 4.2144 15.072L3.5056 6.8H2.8C2.58783 6.8 2.38434 6.71571 2.23431 6.56569C2.08429 6.41566 2 6.21217 2 6C2 5.78783 2.08429 5.58434 2.23431 5.43431C2.38434 5.28429 2.58783 5.2 2.8 5.2H6.8ZM12.4 10C12.4 9.78783 12.3157 9.58434 12.1657 9.43431C12.0157 9.28429 11.8122 9.2 11.6 9.2C11.3878 9.2 11.1843 9.28429 11.0343 9.43431C10.8843 9.58434 10.8 9.78783 10.8 10V13.2C10.8 13.4122 10.8843 13.6157 11.0343 13.7657C11.1843 13.9157 11.3878 14 11.6 14C11.8122 14 12.0157 13.9157 12.1657 13.7657C12.3157 13.6157 12.4 13.4122 12.4 13.2V10ZM8.4 9.2C8.61217 9.2 8.81566 9.28429 8.96569 9.43431C9.11571 9.58434 9.2 9.78783 9.2 10V13.2C9.2 13.4122 9.11571 13.6157 8.96569 13.7657C8.81566 13.9157 8.61217 14 8.4 14C8.18783 14 7.98434 13.9157 7.83431 13.7657C7.68429 13.6157 7.6 13.4122 7.6 13.2V10C7.6 9.78783 7.68429 9.58434 7.83431 9.43431C7.98434 9.28429 8.18783 9.2 8.4 9.2ZM5.808 14.936C5.84208 15.3355 6.02493 15.7077 6.32035 15.9788C6.61577 16.2499 7.00223 16.4002 7.4032 16.4H12.5968C12.9975 16.3998 13.3836 16.2494 13.6786 15.9783C13.9737 15.7072 14.1563 15.3352 14.1904 14.936L14.888 6.8H5.112L5.808 14.936Z"
                        fill="black"
                    />
                </svg>
            </div>
        </template>

        <template v-if="selectable">
            <div class="group-status" @click.prevent.stop="selectGroup">
                <img :src="getImageSrc" alt="" />
            </div>
        </template>
    </div>
</template>

<script>
import fullSelectedIcon from '@/assets/images/full-selected-icon.svg'
import partialSelectedIcon from '@/assets/images/partial-selected-icon.svg'
import noneSelectedIcon from '@/assets/images/none-selected-icon.svg'

export default {
    name: 'AccountsGroup',
    props: {
        editable: {
            type: Boolean,
            default: false
        },
        selectable: {
            type: Boolean,
            default: false
        },
        showBalance: {
            type: Boolean,
            default: false
        },
        groupData: {
            type: Object,
            required: true
        }
    },
    emits: ['open-group', 'select-group', 'edit-group', 'delete-group'],
    computed: {
        getImageSrc() {
            if (this.groupData.status === 'partial') {
                return partialSelectedIcon
            }

            if (this.groupData.status === 'full') {
                return fullSelectedIcon
            }

            if (this.groupData.status === 'empty') {
                return noneSelectedIcon
            }
        }
    },
    methods: {
        openGroup() {
            this.$emit('open-group')
        },

        selectGroup() {
            this.$emit('select-group')
        },

        editGroup() {
            this.$emit('edit-group')
        },

        deleteGroup() {
            this.$emit('delete-group')
        }
    }
}
</script>

<style scoped lang="scss">
.group-item {
    display: flex;
    align-items: center;
    padding: 0.75rem 1rem;
    background: $grey-light;
    margin-bottom: 0.5rem;
    border-radius: 0.5rem;

    .group-link {
        display: flex;
        align-items: center;
        margin-right: auto;
        text-decoration: none;
        color: $black;

        &:hover {
            cursor: pointer;
            color: $primary;

            .group-icon {
                svg {
                    path {
                        stroke: $primary;
                    }
                }
            }
        }
    }

    .group-icon {
        width: 1.25rem;
        height: 1.25rem;
        margin-right: 0.5rem;
    }

    .group-name {
        margin-right: auto;
    }

    .group-balance {
        margin-right: 0.625rem;
        white-space: nowrap;
    }

    .edit-icon,
    .delete-icon {
        width: 1.25rem;
        height: 1.25rem;
        margin-left: 0.625rem;

        &:hover {
            cursor: pointer;

            svg {
                path {
                    fill: $primary;
                }
            }
        }

        &.disabled {
            pointer-events: none;

            svg {
                path {
                    fill: $grey;
                }
            }
        }
    }

    .group-status {
        display: flex;
        cursor: pointer;

        img {
            width: 1rem;
            height: 1rem;
        }
    }
}
</style>
