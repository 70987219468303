<template>
    <div class="transactions-page">
        <div class="container">
            <div class="page-header">
                <router-link :to="{ name: 'homePage' }" class="return-link">
                    <img src="@/assets/images/prev-page-arrow.svg" alt="" />
                </router-link>
                <div class="page-title text-h2 text-bold">Транзакції</div>
            </div>

            <div class="transactions-list">
                <TransactionComponent
                    v-for="transaction in transactionsList"
                    :key="transaction.id"
                    :transaction-info="transaction"
                />
                <template v-if="isActiveRoute">
                    <div
                        v-if="!lastPage"
                        class="load-more-btn text-h4 text-grey text-center"
                        @click="loadTransactions"
                    >
                        Завантажити ще
                    </div>
                    <div v-if="lastPage" class="text-h4 text-grey text-center">
                        Всі транзакції завантажені
                    </div>
                </template>
                <template v-else>
                    <div class="text-h4 text-grey text-center">Немає активного маршруту</div>
                </template>
                <Transition name="fade">
                    <LoaderComponent v-if="showLoader" />
                </Transition>
            </div>

            <template v-if="isActiveRoute">
                <router-link :to="{ name: 'createTransactionPage' }" class="create-transaction-btn">
                    <img src="@/assets/images/plus-icon.svg" alt="" />
                </router-link>
            </template>
        </div>
    </div>
</template>

<script>
import fetchWrapper from '@/helpers/fetch-wrapper.js'
import { useActiveRouteStore } from '@/stores/index.js'
import LoaderComponent from '@/components/LoaderComponent.vue'
import TransactionComponent from '@/components/TransactionComponent.vue'

export default {
    name: 'TransactionsView',
    components: { TransactionComponent, LoaderComponent },
    setup() {
        const activeRouteStore = useActiveRouteStore()

        return { activeRouteStore }
    },
    data() {
        return {
            transactionsList: [],
            pageNum: 1,
            lastPage: false,
            showLoader: true
        }
    },
    computed: {
        isActiveRoute() {
            return this.activeRouteStore.routeId
        }
    },
    mounted() {
        this.checkIfActiveRouteExists()
    },
    methods: {
        async checkIfActiveRouteExists() {
            await this.activeRouteStore.getActiveRouteId()

            if (this.isActiveRoute) {
                await this.loadTransactions()
            } else {
                this.showLoader = false
            }
        },
        async loadTransactions() {
            await fetchWrapper
                .post(`${import.meta.env.VITE_FINANCE_URL}/transactions/load-list`, {
                    route_id: this.activeRouteStore.routeId,
                    page: this.pageNum
                })
                .then((response) => {
                    const transactions = response?.data?.data

                    this.transactionsList.push(...transactions)
                    this.lastPage = response.data.current_page === response.data.last_page

                    this.pageNum++
                })
                .finally(() => {
                    this.showLoader = false
                })
        }
    }
}
</script>

<style scoped lang="scss">
.transactions-page {
    padding: 1.5rem 0;
    height: 100%;
    background: $grey-light;
    overflow: auto;

    .page-header {
        display: flex;
        align-items: center;
        margin-bottom: 1.25rem;

        .return-link {
            display: flex;
            margin-right: 1rem;
        }
    }

    .create-transaction-btn {
        position: fixed;
        right: 2.5rem;
        bottom: 3.125rem;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.5rem;
        height: 2.5rem;
        background: $gradient;
        border-radius: 0.625rem;
        z-index: 100;
    }
}
</style>
