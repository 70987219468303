<template>
    <div class="transactions-list">
        <div class="top-row">
            <div class="title info-title text-h3 text-bold">Транзакції</div>
            <div class="add-btn" @click="addTransaction">
                <img src="@/assets/images/add-transaction-icon.svg" alt="" />
            </div>
        </div>

        <template v-if="transactionsList.length">
            <div class="list-header">
                <div class="sum text-h5 text-grey">Сума</div>
                <div class="account text-h5 text-grey">Рахунок</div>
                <div class="date text-h5 text-grey">Дата</div>
                <div class="delete"></div>
            </div>
            <div class="list-body">
                <div
                    v-for="transaction in transactionsList"
                    :key="transaction.id"
                    class="transaction-item"
                >
                    <div class="sum text-h5">
                        {{ transaction.amount }}
                    </div>
                    <div class="account text-h5">
                        {{ transaction.main_financial_account.name }}
                    </div>
                    <div class="date text-h6">
                        {{ transaction.date }}
                    </div>
                    <div class="delete" @click="handleTransactionDelete(transaction.id)">
                        <img src="@/assets/images/delete-gradient-icon.svg" alt="" />
                    </div>
                </div>
            </div>
        </template>

        <PaymentModal
            ref="paymentModal"
            :entity_id="entity_id"
            :entity_type="entity_type"
            @transaction-added="loadTransactions"
        />

        <ConfirmationModal
            :show="showConfirmationModal"
            modal-title="Ви хочете видалити транзакцію?"
            @submit-action="deleteTransaction"
            @cancel-action="hideTransactionConfirmationModal"
        />
        <LoaderComponent v-if="transactionsLoading" />
    </div>
</template>

<script>
import PaymentModal from '@/components/modals/PaymentModal.vue'
import fetchWrapper from '@/helpers/fetch-wrapper.js'
import LoaderComponent from '@/components/LoaderComponent.vue'
import ConfirmationModal from '@/components/modals/ConfirmationModal.vue'
import { useModalStore } from '@/stores/index.js'

export default {
    name: 'TransactionsList',
    components: { ConfirmationModal, LoaderComponent, PaymentModal },
    props: {
        entity_id: {
            type: [Number, null],
            required: true
        },
        entity_type: {
            type: [String, null],
            required: true
        }
    },
    setup() {
        const modalStore = useModalStore()

        return { modalStore }
    },
    data() {
        return {
            transactionsList: [],
            transactionsLoading: false,
            showConfirmationModal: false,
            transactionToDeleteId: null
        }
    },
    mounted() {
        this.loadTransactions()
    },
    methods: {
        async loadTransactions() {
            this.transactionsLoading = true

            await fetchWrapper
                .post(`${import.meta.env.VITE_FINANCE_URL}/route-transactions/load-list`, {
                    entity_id: this.entity_id,
                    entity_type: this.entity_type
                })
                .then((response) => {
                    this.transactionsList = response.data
                })
                .finally(() => {
                    this.transactionsLoading = false
                })
        },

        addTransaction() {
            this.$refs.paymentModal.showModal()
        },

        handleTransactionDelete(id) {
            this.showConfirmationModal = true
            this.transactionToDeleteId = id
        },

        async deleteTransaction() {
            await fetchWrapper
                .delete(`${import.meta.env.VITE_FINANCE_URL}/route-transactions/delete`, {
                    entity_id: this.entity_id,
                    entity_type: this.entity_type,
                    transaction_id: this.transactionToDeleteId
                })
                .then((response) => {
                    this.hideTransactionConfirmationModal()
                    this.loadTransactions()
                    this.$toast.success('Транзакцію видалено')
                })
        },

        hideTransactionConfirmationModal() {
            this.showConfirmationModal = false
            this.transactionToDeleteId = null
        }
    }
}
</script>

<style scoped lang="scss">
.transactions-list {
    position: relative;
    padding: 1.25rem 0;
    border-bottom: 1px solid $grey-light;

    .top-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.625rem;
    }

    .sum {
        width: calc(25% - 0.835rem);
        padding-right: 1rem;
    }

    .account {
        width: calc(55% - 0.835rem);
        padding-right: 1rem;
    }

    .date {
        width: calc(20% - 0.835rem);
    }

    .delete {
        display: flex;
        justify-content: flex-end;
        width: 1.875rem;
        margin-left: 0.625rem;
    }

    .list-header {
        display: flex;
        align-items: center;
        padding-bottom: 0.625rem;
        margin-bottom: 1.25rem;
        border-bottom: 1px solid $grey-light;
    }

    .transaction-item {
        display: flex;
        align-items: center;
        margin-bottom: 0.625rem;

        &:last-of-type {
            margin-bottom: 0;
        }
    }

    .loader-overlay {
        background: rgba($white, 0.8);
    }
}
</style>
