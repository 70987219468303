import axios from 'axios'
import { useAuthStore } from '@/stores'
import { createToaster } from '@meforma/vue-toaster'

const toaster = createToaster({ position: 'top' })

axios.defaults.baseURL = import.meta.env.VITE_DRIVER_URL
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.withCredentials = true

axios.interceptors.request.use(function (config) {
    const token = useAuthStore()?.user?.access_token

    config.headers.Authorization = token

    return config
})

const handleError = (error) => {
    const authStore = useAuthStore()
    let message = 'An error occurred'

    if (error.response) {
        const { status, data } = error.response

        if ([401, 403, 404].includes(status)) {
            authStore.clearUserSession()
        }
        message = data.message || message
    } else {
        message = error.message || message
    }

    toaster.error(message)
    throw error
}

const fetchWrapper = {
    get(url, params = {}) {
        return axios
            .get(url, { params })
            .then((response) => response.data)
            .catch(handleError)
    },

    post(url, data, options = {}) {
        return axios
            .post(url, data, options)
            .then((response) => response.data)
            .catch(handleError)
    },

    put(url, data) {
        return axios
            .put(url, data)
            .then((response) => response.data)
            .catch(handleError)
    },

    patch(url, data) {
        return axios
            .patch(url, data)
            .then((response) => response.data)
            .catch(handleError)
    },

    delete(url, data) {
        return axios
            .delete(url, { data })
            .then((response) => response.data)
            .catch(handleError)
    }
}

export default fetchWrapper
